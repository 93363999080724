@import 'components/layout/Main/styles/values.scss';

.wrapper {
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-weight: 600;
  color: #8c8c8c;
  display: flex;
  justify-content: space-between;
  transition: border-color 0.2s, box-shadow 0.2s;
  cursor: text;
  &:hover {
    border: 1px solid $color-main-1;
  }

  &:focus-within {
    box-shadow: 0 4px 6px $color-shadow-1;
    border: 1px solid $color-main-1;
  }

  input {
    width: 100%;
    border: none;
    height: 100%;
    min-width: 100px;
    &:focus {
      box-shadow: none;
    }
  }
}

.tagsWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    padding: 2px;
    margin: 0;
  }
}

.icon {
  &:global(.ant-btn) {
    svg {
      margin: 0;
    }
  }
}
