.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin-inline-start: -4px;
  margin-inline-end: 8px;
}

.ant-form-vertical {
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    order: 1;
    margin-inline-start: 4px;
  }
}
