@import 'components/layout/Main/styles/values.scss';

.noWrap {
  :global {
    .ant-card.criclebox .ant-table-tbody > tr > td {
      white-space: pre-wrap;
    }
  }
}

.documentRow {
  li {
    list-style: none;
    margin-bottom: 5px;
  }
}

.saved {
  color: $color-success-1;
  white-space: nowrap;
  font-size: 12px;
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 6px;
  justify-content: flex-start;

  @media (min-width: 576px) {
    justify-content: flex-end;
  }
}
