.list {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    span {
      display: inline-block;
      margin-right: 5px;
    }
  }
}
