$color-black-1: #141414;
$color-black-2: rgba(0, 0, 0, 0.65);

$color-grey-0: #f5f5f5;
$color-grey-1: #fafafa;
$color-grey-2: #8c8c8c;

$color-main-1: #6d64c6;
$color-main-2: darken($color-main-1, 15);

$color-danger-1: #f5222d;
$color-success-1: #52c41a;
$color-warning-1: #fadb14;

$color-shadow-1: rgb(0 0 0 / 12%);
$color-shadow-2: rgb(0 0 0 / 5%);

:export {
  colorMain1: $color-main-1;
  colorMain2: $color-main-2;
}
