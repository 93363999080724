@import 'components/layout/Main/styles/values.scss';

.icon {
  color: $color-main-1;
}

.background {
  background-color: $color-main-1;
}

.paymentsMenuItem {
  &:global(.ant-menu-item) {
    :global(.icon) {
      svg {
        path {
          fill: white !important;
        }
      }
      background-color: $color-main-1 !important;
      box-shadow: none !important;
    }
  }
}

.paymentsButton {
  padding: 10px 16px;
  color: white;
  transition: background-color 0.2s;
  border-radius: 8px;
  background-color: $color-main-1;
  transition: opacity 0.2s;

  .icon {
    color: white;
  }

  &:hover {
    opacity: 0.8;
  }
}
