@import 'components/layout/Main/styles/values.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  :global {
    .ant-skeleton {
      width: calc(25% - 18px);
      .ant-skeleton-button {
        width: 100%;
      }
      @media screen and (max-width: 518px) {
        width: calc(50% - 12px);
      }
    }
  }
}

.item {
  width: calc(25% - 18px);
  height: 70px;
  border: 1px solid #d9d9d9;
  background: #fff;
  transition: all 0.2s;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 518px) {
    width: calc(50% - 12px);
    height: 80px;
  }
}

.itemActive {
  border-color: $color-main-1;
  box-shadow: 0 0 0 2px rgba($color-main-1, 0.1);
}
