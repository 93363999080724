@import 'components/layout/Main/styles/values.scss';

.editor {
  :global {
    .ql-snow {
      &.ql-container {
        border-color: #d9d9d9;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &.ql-toolbar {
        border-color: #d9d9d9;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        .ql-picker-label:hover,
        .ql-picker-label.ql-active,
        .ql-picker-item:hover,
        button.ql-active,
        button:hover {
          color: $color-main-1;
          .ql-stroke {
            stroke: $color-main-1;
          }
          .ql-fill {
            fill: $color-main-1;
          }
        }
      }
    }
  }
}
