.formSpace {
  overflow-y: auto;
  width: 100%;
}

.thead {
  &:after {
    content: '';
    display: block;
    margin-bottom: 12px;
    width: 100%;
  }
}

.table {
  width: 100%;

  th,
  td {
    padding-right: 16px;

    &:last-of-type {
      padding-right: 0px;
    }
  }

  td {
    padding-bottom: 16px;
  }

  tr:last-of-type td {
    padding-bottom: 0px;
  }
}
