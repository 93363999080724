.secondText {
  padding-top: 6px;
  margin: 0;
}

.link {
  opacity: 1;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.8;
  }
}
