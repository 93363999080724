@import 'components/layout/Main/styles/values.scss';

.button {
  background: red;
  border-radius: 12px;
  border: 0;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  background-color: $color-main-1;
  color: #fff;
  padding: 24px 30px 24px 24px;
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  &:hover {
    transform: scale(1.04);
  }
  &:active {
    transform: scale(0.96);
  }
}
