.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 40px;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-select-selector {
  min-height: 40px;
}
