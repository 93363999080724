.wrapper {
  width: 100%;
  height: 100%;
  :global(.ant-spin-nested-loading),
  :global(.ant-spin-container) {
    width: 100%;
    height: 100%;
    :global(.ant-spin) {
      max-height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      :global(.ant-spin-text),
      :global(.ant-spin-dot) {
        position: static;
        margin: 0;
      }
    }
  }
}

.absolute {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media print {
  .wrapper {
    z-index: -1;
  }
}
