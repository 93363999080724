.card {
  :global(.ant-card-body) {
    padding: 0;
  }
}

.wrapperTable {
  height: 100%;
  overflow: auto;
}

.rowPointer {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.header {
  padding: 0 24px;
}
