.card {
  width: 50%;
  max-height: 90vh;
  min-width: 300px;
  max-width: 600px;
  margin: 0 auto;
  transform: translateY(5vh);
}

.space {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.logo {
  height: 40px;
}

.content {
  height: 60vh;
  overflow: auto;
}
